import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item', 'label', 'field', 'form', 'button', 'sourceType']

  connect() {
    this.selectedItems = new Set([])
    this.#syncItemField()
  }

  selectItem(event) {
    if (event.target.checked) {
      this.selectedItems.add(event.target.value)
    } else {
      this.selectedItems.delete(event.target.value)
    }

    this.#syncItemField()
  }

  itemTargetConnected(target) {
    if (this.selectedItems.has(target.value)) {
      target.checked = true
    }
  }

  resetSelectedItems(event) {
    this.selectedItems.clear()
    this.#syncItemField()

    this.sourceTypeTarget.value = event.params.sourceType

    if (this.sourceTypeTarget.value == 'scratch') {
      const nameField = document.createElement('input')
      nameField.type = 'hidden'
      nameField.name = 'name'
      this.formTarget.appendChild(nameField)
    } else {
      const nameField = this.formTarget.querySelector('input[name="name"]')
      if (nameField) {
        nameField.remove()
      }
    }
  }

  trackNameFieldChanged(event) {
    const nameField = this.formTarget.querySelector('input[name="name"]')
    nameField.value = event.target.value
    if (nameField.value.trim().length > 0) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }

  #syncItemField() {
    if (this.selectedItems.size > 0) {
      this.labelTarget.innerHTML = `${this.selectedItems.size} selected`
      this.buttonTarget.disabled = false
    } else {
      this.labelTarget.innerHTML = ''
      this.buttonTarget.disabled = true
    }

    this.fieldTarget.value = Array.from(this.selectedItems).join(',')
  }
}
