import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static values = {
    roomUrl: String,
    loadingUrl: String,
  }

  connect() {
    this.counter = 0
    this.timer = setTimeout(() => this.#poll(), 500)
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  #poll() {
    this.counter += 1

    if (this.counter > 15) {
      Sentry.captureMessage(`Room setup for ${this.roomUrlValue} timed out`)
      this.#visitRoom()
    }

    fetch(this.loadingUrlValue)
      .then((response) => response.json())
      .then((data) => {
        if (data.ready) {
          this.#visitRoom()
        } else {
          this.timer = setTimeout(() => this.#poll(), 1500)
        }
      })
  }

  #visitRoom() {
    Turbo.visit(this.roomUrlValue, { action: 'replace' })
  }
}
