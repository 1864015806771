{
    let _global =
      typeof window !== 'undefined' ?
        window :
        typeof global !== 'undefined' ?
          global :
          typeof globalThis !== 'undefined' ?
            globalThis :
            typeof self !== 'undefined' ?
              self :
              {};

    _global.SENTRY_RELEASE={id:"38a619be4788b97cb67919ba7fb7930a1a94fe49"};}