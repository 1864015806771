import { Controller } from '@hotwired/stimulus'
import { observeMutations } from '../helpers/mutation_observer_helpers'

export default class extends Controller {
  static values = {
    frameId: String,
  }

  connect() {
    const frameElement = document.getElementById(this.frameIdValue)
    if (frameElement) {
      observeMutations.bind(this)(this.watchFrameEvents, frameElement, {
        subtree: true,
        attributeFilter: ['busy'],
      })
    }
  }

  watchFrameEvents(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.target.ariaBusy === 'true') {
        this.element.dataset.watchingFrameBusy = 'true'
      } else {
        this.element.dataset.watchingFrameBusy = 'false'
      }
    })
  }
}
