import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.update()
  }

  update() {
    if (this.element.value === '') {
      this.element.classList.remove('text-neutral-900')
      this.element.classList.add('text-neutral-200')
    } else {
      this.element.classList.remove('text-neutral-200')
      this.element.classList.add('text-neutral-900')
    }
  }
}
