import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    location: String,
    block: { type: String, default: 'start' },
  }

  scrollToLocation() {
    this.targetElement.scrollIntoView({
      behavior: 'auto',
      block: this.blockValue,
    })
  }

  get targetElement() {
    return document.getElementById(this.locationValue)
  }
}
