import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import Swal from 'sweetalert2'

export default class extends Controller {
  static values = { source: String, url: String }
  static targets = ['dialog', 'dialogSubmitter']

  connect() {
    this.submitEvent = null
    this.submitterElement = null

    this.element.addEventListener(
      'turbo:before-fetch-request',
      this.ensureIdentifiedOrRequestIdentification.bind(this)
    )

    this.element.addEventListener(
      'turbo:submit-start',
      this.ensureIdentified.bind(this)
    )
  }

  async ensureIdentifiedOrRequestIdentification(event) {
    if (this.#anonymousSession()) {
      this.#cleanup()
      event.preventDefault()

      this.submitEvent = event
      this.submitterElement = event.target.querySelector('[type=submit]')
      if (this.submitterElement) {
        this.submitterElement.disabled = true
      }

      await get(this.urlValue, { responseKind: 'turbo-stream' })
    }
  }

  ensureIdentified(event) {
    if (this.#anonymousSession()) {
      event.detail.formSubmission.stop()
    }
  }

  async identify(event) {
    const form = event.target.form
    if (this.#providedValidInfo(form) && form.reportValidity()) {
      this.dialogSubmitterTarget.disabled = true

      const response = await post(this.urlValue, { body: new FormData(form) })
      if (response.ok) {
        this.#setParticipantAsIdentified()
      } else {
        this.#identificationFailed()
      }

      this.#closeDialogAndResumeSubmission()
    } else {
      form.reportValidity()
    }
  }

  didntIdentify() {
    if (this.submitterElement) {
      this.submitterElement.disabled = false
    }

    this.#closeDialogAndResumeSubmission()
  }

  #cleanup() {
    this.submitEvent = null
    this.submitterElement = null
  }

  #closeDialogAndResumeSubmission() {
    this.dialogTarget.close()
    this.dialogTarget.remove()
    this.submitEvent.detail.resume()
  }

  #providedValidInfo(form) {
    let valid = true

    if (form.participant_name.value.trim().length === 0) {
      form.participant_name.setCustomValidity("Name can't be blank")
      valid = false
    } else {
      form.participant_name.setCustomValidity('')
    }

    if (form.participant_email.value.trim().length === 0) {
      form.participant_email.setCustomValidity("Email can't be blank")
      valid = false
    } else {
      form.participant_email.setCustomValidity('')
    }

    if (!this.#validEmail(form.participant_email.value.trim())) {
      form.participant_email.setCustomValidity('Invalid email')
      valid = false
    } else {
      form.participant_email.setCustomValidity('')
    }

    return valid
  }

  #onIdentificationFailedDialogClosed() {
    if (this.submitterElement) {
      this.submitterElement.disabled = false
    }

    this.submitterElement.focus()
  }

  // Regex from URI::MailTo::EMAIL_REGEXP
  #validEmail(email) {
    return email
      .toLowerCase()
      .match(
        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      )
  }

  #anonymousSession() {
    return this.#participantAnonymous() && !this.#userId()
  }

  #userId() {
    return document.head.querySelector("meta[name='user-id']").content
  }

  #participantAnonymous() {
    return (
      document.head.querySelector("meta[name='participant-anonymous']")
        .content === 'true'
    )
  }

  #setParticipantAsIdentified() {
    document.head.querySelector("meta[name='participant-anonymous']").content =
      'false'

    if (this.submitterElement) {
      this.submitterElement.disabled = false
    }
  }

  #identificationFailed() {
    Swal.fire({
      html: `
        <div class="flex flex-col space-y-2">
          <div class="text-left text-lg font-bold text-neutral-900">
            Something went wrong
          </div>
          <div class="text-left font-normal text-base text-neutral-900 font-sans leading-7">
            Sorry, something went wrong on our end. Please try again later.
          </div>
        </div>
      `,
      confirmButtonText: 'OK',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn-primary mr-3',
        popup: '!rounded-xl',
        actions: '!justify-end !mx-0 !px-7',
      },
    }).then((_result) => this.#onIdentificationFailedDialogClosed())
  }
}
